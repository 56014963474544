<template>
  <div id="covid" class="mt-4 px-5">
    <h2 class="text-center">WE’RE IN THIS… TOGETHER</h2>

    <p>
      With the Covid-19 crisis looming large over businesses, the onus is on us
      to take relevant safety measures and be mindful of our next steps. We will
      monitor the global situation and offer continued support to our employees,
      customers, and other stakeholders as needed. Your health is a priority for
      us, so you can read more about our business preparedness and our safety
      measures on our website.
    </p>

    <p>We have implemented the following measures to support you:</p>

    <ul>
      <li>
        In addition to regular cleaning, which is part of our normal course of
        business, we have instructed our associates to increase practices with
        additional cleaning of high-touch surfaces and objects.
      </li>
      <li>
        We recommend all employees to take precautionary health measures,
        including frequent hand washing, social distancing, staying home when
        sick, and pausing all non-essential travel.
      </li>
    </ul>

    <p>
      You have our pledge that you will have our continued support. For further
      queries regarding our crisis preparedness, please write to us at
      info@abpoolsva.com.
    </p>

    <p>Stay safe.</p>

    <p>Regards,</p>

    Denis Ferrera<br />
    Owner, AB Pools LLC.
  </div>
</template>

<script>
export default {
  name: "Covid",

  components: {},

  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="scss">
.covid ul {
  list-style-type: decimal;
}
</style>
